<template>
  <div class="monordo">
    <div class="full-section">
      <br>
      <p>
        During four weeks, we conceptualized and developped an application to
        generate and manage medical perscriptions. We analyzed the market and
        found out that many medical services use varying tools to handle
        perscriptions online since the beginning of the COVID-19 crisis, however
        there doesn't exist a unified tool to handle all the use cases of such
        generator.
      </p>
      <br>
      <p>
        Thus, we wanted to develop a tool that could safely handle sensitive
        data, and ensure no perscription fraud could be commited.
      </p>
      <br>
      <p>
        Firstly, we spent a week conceptualizing our application, using various
        UML diagrams such as <strong>Use case</strong>,<strong> MCD</strong>, and
        <strong>
          Sequence
        </strong>
        diagrams.
      </p>
      <br>
    </div>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/monordo/album/2.png" alt="" />
    </div>
    <br>
    <p>
      Then, I worked on the design of the Desktop and mobile interface. I wanted to showcase the clean and reassuring design of a medical application, with colors such as blue, green and white.
    </p>
    <br>
    <p>Furthermore, I worked on the front-end team to integrate the desktop interface with <strong>Vue.js</strong> and using an <strong>API</strong> to read and interpret the unique QR code generated for each perscription.</p>
    <br>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/monordo/album/1.png" alt="" />
    </div>
    <div class="full-section">
      <br>
      <p>And of course my job also entailed managing the team and creating a Backlog to organize the progress of the front-end and back end team.
      </p>
      <br>
      <p>Anticipating delays, and coordinating the merge of the front-end and the back-end, we all ended up managing to present a more than effective PoC to our jury and got a really good grade for the end of our Bachlor in CS ! 🚀</p>
    <br>
    </div>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/monordo/album/3.png" alt="" />
    </div>
    <div class="lesson-box">
    <h2>What did I learn ?</h2>
    <br>
    <p>
      This project was one of my most enriching experiences so far during my studies. It was my first time working with several developers on the same project, so I learned how to <strong>collaborate with a diverse team</strong>, and to <strong>take advantage of eachothers strenghts and weaknesses</strong>
    
    </p>
    <p>
      I also learned that there is more to an application than just an idea, design, and a bit of code. I learned to be more rigorous when <strong>conceptualizing an application</strong>. Visualizing different users, how they might interact with eachother and with the application...
    </p>
    <p>
      And of course, I loved working with the boys on my team, this will stay a great memory in my mind ! ❤️
    </p>
  </div>
  </div>
  
</template>

<script>
export default {
  name: "MonOrdo",
};
</script>
<style scoped></style>
